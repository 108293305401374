import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import apiUrlBase from './../config/apiUrlBase';

import Sidebar from './Sidebar';

const Search = (props) => {

    // abort controller
    var controller = new AbortController();
    var signal = controller.signal;

    const [loading, setLoading] = useState(false)

    const [search, setSearch] = useState("");
    const [results, setResults] = useState([])
    const [categories, setCategories] = useState([]);

    // pagination state
    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();

    const [loadTimeout, setLoadTimeout] = useState(null);
    const [fetchTimeout, setFetchTimeout] = useState(null);

    // pagination function
    const changePage = (pageToChangeTo) => {
        if(pageToChangeTo < 1 || pageToChangeTo > lastPage){
            // console.log("Page to change to: " + pageToChangeTo + " is not within boundries");
        }
        else {
            setCurrentPage(pageToChangeTo);
            setLoading(true);
        }
    }

    const endLoadingWithTimeout = () => {
        if (loadTimeout !== null) {
            loadTimeout.refesh()
        }
        else {
            const t = setTimeout(() => {
                setLoading(false)
                setLoadTimeout(null)
            }, 1000)
            setLoadTimeout(t);
        }
    }

    const fetchItems = async (apiUrl = `${apiUrlBase}/post/search/${search}?page=${currentPage}`) =>  {
        // console.log("load");
        try {
            await fetch(apiUrl, {
                method: "GET",
                signal: signal,
                headers : { 
                  'Content-Type': 'text/html',
                  'Accept': 'text/html'
               }})
                .then(async (response) => {
                    
                    //throw errors if issues
                    if (response.status === 500) {
                        // console.log("500");
                    }
                    else if(response.status === 404) {
                        // console.log("404");
                    }
                    else if(response.status === 419) {
                        // console.log("419");
                    }
    
                    const data = await response.json();

                    console.log(data)
                    setResults(data.posts);
                    setCategories(data.categories);
                    setCurrentPage(data.currentPage);
                    setLastPage(data.pageCount);
                    endLoadingWithTimeout();
            })
        } catch (error) {
            
        }
    }

    useEffect(() => {  
        
        if (search !== "") {
            setLoading(true);
            fetchItems();
        }
        
    }, [search])



    const reset = (e) => {
        // console.log("reset");
        setSearch("");
        props.setDisplay(false);
    }
    
    const handleChange = (e) => {

        var inputValue = e.target.value;
        setSearch(e.target.value);
        //console.log("this is the search: " + search + " : " + inputValue)
        if(inputValue !== "" && props.display === false) {
            props.setDisplay(true);
        }
        else if (inputValue === "" && props.display === true) {
            props.setDisplay(false);
        }
    }


    function readingTime(text) {
        const wpm = 200;
        const words = wordCount(text);
        return Math.ceil(words / wpm);     
}

    function wordCount(text) {
            return text.trim().split(/\s+/).length;   
    }

    return (

        <div>
            <div className="form-group search">
                <div style={{'display': 'flex', 'justifyContent': 'end'}}>
                    <input type="hidden" name="_token" value="X1U09v2PexYHdViQdW2VLwBFID494f3po6dWLXPZ" />
                    {/* <button aria-label="Search" type="submit" className="searchButton"><i className="fa fa-search" aria-hidden="true"></i></button> */}
                    <div aria-label="Search" className="searchButton"><i className="fa fa-search" aria-hidden="true"></i></div>
                    <input type="text" name="query" className="searchInput" id="search" placeholder="Search..." value={props.query} required onChange={(e) => handleChange(e)} />
                    <label style={{'display' : 'none' }} htmlFor="search">Search</label>
                </div>
            </div>
            {search !== "" ?


            <main>
                    <h1>Search Results for "{search}"</h1>
                    
                    <div className="container">
                        <div className="posts_container">
                        {loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> : <>
                        {lastPage === 0 ? "No results found ;(" : 
                            <>
                                <p className="page-num">Page {currentPage} of {lastPage}</p>
                                {results.map((post) => {

                                    let isCurrentPost = false;
                                    if(typeof(props.currentId) !== 'undefined') {
                                        //console.log("is currednt!", props.currentId, post.slug);
                                        if (props.currentId == post.slug) {
                                            isCurrentPost = true;
                                            //console.log("is current!");
                                        }
                                        
                                        
                                    }


                                    return (
                                        <Link key={post.id} to={"/post/" + post.slug} className="unlinkStyle" onClick={props.toggleToTop}>
                                        <article className="post_link">
                                            <img className="post_thumbnail" src={post.image} alt={post.title} loading="lazy" />

                                            <div className="post_container">
                                                <h2 className="post_title">{post.title}</h2>
                                                    {/* Strip the body of tags and get the first 200 characters
                                                <p>{(post.body.replace(/(<([^>]+)>)/ig,"").substring(0,200) + "...")}</p> */}
                                                
                                            </div>
                                            <div className='post_link_details'>
                                                <div>
                                                <p><span className='post_link_details_bold'>Posted:</span> {new Date(post.createdAt).toLocaleDateString('en-GB',{ year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                                    { post.category_id ? <p><span className='post_link_details_bold'> Category: </span> {categories.find(cat => post.category_id === cat.id).title} </p> : ''}
                                                </div>
                                                
                                                <div>
                                                    <p><span className='post_link_details_bold'>Words:</span> {wordCount((post.body))}</p>

                                                    <p><span className='post_link_details_bold'>Reading time:</span> {readingTime((post.body))} min</p>
                                                </div>
                                                
                                            </div>
                                        </article>
                                    </Link>
                                    )
                                })}
                                </>
                            }



                            {/* if the current page isn't 1, show last page button */}
                            {lastPage !== 0 && currentPage !== 1 ?
                                <button className="btn" onClick={() => {changePage(currentPage - 1); props.toggleToTop()}}>Last Page</button> :
                                null
                            }

                            {/* if the current page isn't equal to the last page, show next page button */}
                            {lastPage !== 0 && currentPage !== lastPage ?
                                <button className="btn" onClick={() => {changePage(currentPage + 1); props.toggleToTop()}}>Next Page</button> :
                                null
                            }
                            
                            {/* <p>Current Page: {currentPage}</p>
                            // <p>Last Page: {lastPage}</p> */}
                            </>
                            }
                        </div>

                        <Sidebar recent={results.recent_post}/>
                        </div>


                    
            </main>    
            
            
            
            : null}
        </div>
    );
}

export default Search;