import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ReactHtmlParser from 'html-react-parser';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Sidebar from './partials/Sidebar';
import '../css/portfolio.css';

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import apiUrlBase from './config/apiUrlBase';

const Portfolio = (props) => {

    const [loading, setLoading] = useState(true);
    const [revealedPortfolio, setRevealedPortfolio] = useState(0);
    const [portfolios, setPortfolios] = useState([]);

  
    const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
   
        try {
            const req = await fetch(apiUrl, {
                method: "GET",
                signal: signal,
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }})
                .then(async (response) => {
                    
                    //throw errors if issues
                    if (response.status === 500) {
                        // console.log("500");
                    }
                    else if(response.status === 404) {
                        // console.log("404");
                    }
                    else if(response.status === 419) {
                        // console.log("419");
                    }
                    else if (response.status !== 400) {
                        // console.log(response.status);
                    }
    
                    const data = await response.json();
                    
                    setPortfolios(data);
                    setLoading(false)
                   
            })
        }
        catch(error) {
            // console.log("Error ", error);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchItem(controller.signal);
      
        return () => {
          controller.abort(); 
        };
      }, []);

      useEffect(() => {
        document.title = "Portfolio | jsparrow.uk";
      });

    const expand = (id) => {
        if (id === revealedPortfolio) {
            setRevealedPortfolio(0);
        }
        else {
            setRevealedPortfolio(id);
        }
        // console.log(revealedPortfolio, id);
    }

    const featuresToArr = (featureString) => {

        const arr = featureString.split("\"");
        return arr.filter(feature => feature.length > 1);
    }

    const getMiniFeatureListContent = (input, max) => {
        
        if (input === []) {
            return null
        } 
        let newArray = featuresToArr(input.features).filter((feature) => {
                                                
            if(!(miniFeatureListCount >= max ||
                feature == "html" ||
                feature == "css" ||
                feature == "js" ||
                feature == "php")) {

                miniFeatureListCount++;

                return feature;
            }

        });

        newArray = newArray.map((feature, i) => {
            if (feature == null) {
                return null;
            }

            let output = feature;
            output = output.replace(/^[A-Za-z]/, output.charAt(0).toUpperCase());

            if(newArray.indexOf(feature) == newArray.length - 1) {
                return <div key={`${input.id}-mini-list-${feature}-${i}`}>{`${output}...`}</div>;
            } 
            else {
                return <div key={`${input.id}-mini-list-${feature}-${i}`}>{`${output},`}</div>;
            }
        });
        return newArray;

        /*            else if(miniFeatureListCount == max - 1) {
                miniFeatureListCount++;
                let output = feature;
                output = output.replace(/^[A-Za-z]/, output.charAt(0).toUpperCase()); 
                return <div key={`${input.id}-mini-list-${feature}-${i}`}>{output}...</div>
            }*/
    }

    let miniFeatureListCount = 0;


    return (
        <div className="main-container">
        <Header toggleTheme={props.toggleTheme}/>

        <main>
                {loading ? null : <div> 
                     <div className="homepage-quick-links cv-text">
                             <p>You can find my latest CV <a href="/Curriculum_Vitae.pdf">here</a>.</p>
                     </div>
                     <br />
                     <hr />
                     <h1 className="portfolio-title">Projects</h1> 
                    
                     </div>}
                {loading ? <div className="spinner"><ClipLoader color='#356f2a'/></div> : 
                        <div className="portfolio-grid-container">
                        {[...portfolios].reverse().map((portfolio) => {
                            miniFeatureListCount = 0;
                            return ([
                                
                                <div className="portfolio-link" onClick={() => expand(portfolio.id)} key={portfolio.id}>
                                            <div className="portfolio-link-image">
                                                <img src={portfolio.image} alt={portfolio.title} />
                                            </div>
                                            <div className="portfolio-link-text">
                                                <h3>{portfolio.title}</h3>
                                                <br/>
                                                <p>{portfolio.body}</p>
                                            </div>  
                                            <div className="mini-feature-list">
                                            {getMiniFeatureListContent(portfolio, 2).map((lad) => lad)}
                                            </div>                        
                                </div>,
                                
                                    
                                    <div key={portfolio.id + "expandable"} className={revealedPortfolio === portfolio.id ? "expandable expanded" : "expandable"} id={portfolio.id}>
                                        <div className="expandable-top">
                                            <img src={portfolio.revealed_image} alt={portfolio.title} />
                                            <div className="expandable-text">
                                                <h3>{portfolio.revealed_title}</h3>
                                                <p>{portfolio.revealed_body}</p>
                                            </div>
                                        </div>
                                        <div className="expandable-middle">
                                            {featuresToArr(portfolio.features).map((feature) => {
                                                switch(feature){
                                                    case "html":
                                                        return (
                                                            <div key={`${portfolio.id}html`}><i className="fab fa-html5"></i> <small>HTML5</small></div>
                                                        )
                                                    case "css":
                                                        return (
                                                            <div key={`${portfolio.id}css`}><i className="fab fa-css3-alt"></i> <small>CSS3</small></div>
                                                        )
                                                    case "js":
                                                        return (
                                                            <div key={`${portfolio.id}js`}><i className="fab fa-js"></i> <small>JavaScript</small></div>
                                                        )
                                                    case "php":
                                                        return (
                                                            <div key={`${portfolio.id}php`}><i className="fab fa-php"></i> <small>PHP</small></div>
                                                        )
                                                    case "laravel":
                                                        return (
                                                            <div key={`${portfolio.id}laravel`}><i className="fab fa-laravel"></i> <small>Laravel</small></div>
                                                        )
                                                    case "react":
                                                        return (
                                                            <div key={`${portfolio.id}react`}><i className="fab fa-react"></i> <small>React</small></div>
                                                        )
                                                    case "wordpress":
                                                        return (
                                                            <div key={`${portfolio.id}wordpress`}><i className="fab fa-wordpress"></i> <small>Wordpress</small></div>
                                                        )
                                                    case "responsive":
                                                        return (
                                                            <div key={`${portfolio.id}responsive`}><i className="fas fa-mobile-alt"></i> <small>Responsive Design</small></div>
                                                        )
                                                    case "unity":
                                                        return (
                                                            <div key={`${portfolio.id}unity`}><i className="fab fa-unity"></i> <small>Unity</small></div>
                                                        )
                                                    case "python":
                                                        return (
                                                            <div key={`${portfolio.id}python`}><i className="fab fa-python"></i> <small>Python</small></div>
                                                        )
                                                    default:
                                                        if(feature.length <= 0) {
                                                            return null;
                                                        }
                                                        let output = feature;
                                                        output = output.replace(/^[A-Za-z]/, output.charAt(0).toUpperCase());
                                                        return (
                                                            <div key={`${portfolio.id}${feature}`}><small>{output}</small></div>
                                                        )
                                                }
                                            })}
                                        </div>
                                        <div className="expandable-bottom">

                                            {portfolio.link_to_site  ? 
                                                <a className="btn" href={portfolio.link_to_site}><i className="far fa-window-maximize"></i> Live Demo</a> : null
                                            }

                                            {portfolio.link_to_source ?
                                                <a className="btn" href={portfolio.link_to_source}><i className="fas fa-code"></i> Source</a> :
                                                // <p > style="color:#a1a1a1; margin: auto 0;"
                                                <p className="sourcePrivate">
                                                    <i className="fas fa-lock"></i> Source Private
                                                </p>
                                            }

                                            {portfolio.link_to_blog ? 
                                                <a className="btn" href={portfolio.link_to_blog}><i className="fas fa-pen"></i> Blog Page</a> : null
                                            }

                                            
                                        </div>
                                
                                   
                                    </div>]
                            )
                        }
                        
                )}</div>}

            <Footer toggleToTop={props.toggleToTop} />
        </main>
            
        </div>
    );
}

export default Portfolio;
