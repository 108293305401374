import React from 'react'

import {Link} from 'react-router-dom';

const Header = (props) => {



    return (
            <header className="topbar">
                <div className="topbar-section">
                    <div className="title">
                        <h1>James Sparrow</h1>
                        <button className="colourModeButton" onClick={props.toggleTheme}>
                                <i className="fas fa-lightbulb"></i>
                        </button>  
                
                    </div>

                    <nav className="navbar">
                        <Link to="/home">Home</Link>
                        <Link to="/posts">Blog</Link>
                        <Link to="/portfolio">Portfolio</Link>
                    </nav> 
                </div>
            </header>
    );
}

export default Header;