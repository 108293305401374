import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Header from './partials/Header';
import Footer from './partials/Footer';
import Search from './partials/Search';
import Sidebar from './partials/Sidebar';
import ReactHtmlParser from 'html-react-parser'; 

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import apiUrlBase from './config/apiUrlBase';

export default function Single(props) {

    let history = useNavigate();

    // search state
    const [searchDisplay, setSearchDisplay] = useState(false);
    const setDisplay = (input) => setSearchDisplay(input);

    // post state
    const [post, setPost] = useState({});
    const [category, setCategory] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
        
        // console.log("load");
        // console.log(apiUrl, {signal});
        try {
            const req = await fetch(apiUrl, {
                method: "GET",
                signal: signal,
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }})
                .then(async (response) => {
                    
                    //throw errors if issues
                    if (response.status === 500) {
                        // console.log("500");
                    }
                    else if(response.status === 404) {
                        // console.log("404");
                    }
                    else if(response.status === 419) {
                        // console.log("419");
                    }
                    else if (response.status !== 400) {
                        // console.log(response.status);
                    }
    
                    const data = await response.json();
                    setPost(data);

                    if (!data.category_id) {
                        setLoading(false);
                    }
                    const categoryReq = await fetch(apiUrlBase + "/category/" + data.category_id, {
                        method: "GET",
                        signal: signal,
                        headers : { 
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }}).then (async (response) => {
                            // console.log("well we got here I guess")
                            const data = await response.json();
                            setCategory(data.category)
                            setLoading(false);
                        })


                   
            })
        }
        catch(error) {

            // console.log("OKAY I ERROR AND LOAD IS "+loading)
            // console.log("Error ", error);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchItem(controller.signal);
      
        return () => {
          controller.abort(); 
        };
      }, []);

      useEffect(() => {
        document.title = post.title ? post.title + " | Jsparrow.uk" : "Jsparrow.uk";
      });

      const date = new Date(post.createdAt);
    return (
        <div className="main-container">
        <Header toggleTheme={props.toggleTheme}/>

            <main>
            {/* <Search currentId={props.match.params.id} display={searchDisplay} setDisplay={setDisplay}/> */}
            
            {searchDisplay ? null : 
                <div className="container">
                {loading ? <div className="spinner"><ClipLoader color='#356f2a'/></div> :
                        <div className="post">
                            <img className="post_header_image" src={post.image} alt={post.title}></img>
                            <div className="post_container">
                                <div className="timestamp">{new Date(post.createdAt).toLocaleDateString('en-GB',{ year: 'numeric', month: 'long', day: 'numeric' })}</div>
                                {category && <Link to={"/category/" + category.id}>This post is in the category: <em>{category.title}</em></Link>}
                                <h1>{post.title}</h1>
                                <div className="post_content_container">
                                {ReactHtmlParser(post.body)}
                                </div>
                                <button className="btn readMore" onClick={() => {history(-1); props.toggleToTop()}}>
                                    <i className="fas fa-arrow-left"></i> Go Back
                                </button>
                            </div>
                        </div>
                    } 
                    {/* {loading ? null : <Sidebar recent={state.recent_post}/>} */}
                </div>
                 }
                <Footer toggleToTop={props.toggleToTop} />
            </main>
        </div>
    )
}
