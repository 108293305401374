import React, {useState, useEffect, useLayoutEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Header from './partials/Header';
import Footer from './partials/Footer';
import Search from './partials/Search';
import Sidebar from './partials/Sidebar';
import ReactHtmlParser from 'html-react-parser'; 

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import apiUrlBase from './config/apiUrlBase';

export default function Bookshelf(props) {

    let history = useNavigate();
// search state
const [searchDisplay, setSearchDisplay] = useState(false);
const setDisplay = (input) => setSearchDisplay(input);

// post state
const [post, setPost] = useState({});
const [category, setCategory] = useState({});
const [loading, setLoading] = useState(false);

useLayoutEffect(() => {
    // window.scrollTo(0, 0)
});

const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
    
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                
                //throw errors if issues
                if (response.status === 500) {
                    // console.log("500");
                }
                else if(response.status === 404) {
                    // console.log("404");
                }
                else if(response.status === 419) {
                    // console.log("419");
                }
                else if (response.status !== 400) {
                    // console.log(response.status);
                }

                const data = await response.json();
                setPost(data);

                if (!data.category_id) {
                    setLoading(false);
                }
                const categoryReq = await fetch(apiUrlBase + "/category/" + data.category_id, {
                    method: "GET",
                    signal: signal,
                    headers : { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }}).then (async (response) => {
                        // console.log("well we got here I guess")
                        const data = await response.json();
                        setCategory(data.category)
                        setLoading(false);
                    })


               
        })
    }
    catch(error) {

        // console.log("OKAY I ERROR AND LOAD IS "+loading)
        // console.log("Error ", error);
    }
}

useEffect(() => {
    const controller = new AbortController();
    fetchItem(controller.signal);
  
    return () => {
      controller.abort(); 
    };
  }, []);

  useEffect(() => {
    document.title = post.title ? post.title + " | Jsparrow.uk" : "Jsparrow.uk";
  });


    return (
        <div className="main-container"> 
            <Header toggleTheme={props.toggleTheme}/>

            <main>
            {/* <Search currentId={props.match.params.id} display={searchDisplay} setDisplay={setDisplay}/> */}
            
            {searchDisplay ? null : 
                <div className="container">
                {loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> :
                        <div className="post">
                            <div className="post_container resource-container">
                                <h1>Jimjam's Personal Library</h1>
                                <section>
                                </section>
                                <section className='li-book'>
                                 
                                <br /><br />
                                <h2>Fiction</h2>
                                <hr />

                                 <h3>Fiction (Si-Fi / Fantasy):</h3>
                                 <ul>
                                 <li>Dune<em> - Frank Herbert</em></li>
                                 <li>Halo: The Fall of Reach<em> - Eric Nylund</em></li>
                                 <li>Halo: The Flood<em> - William C. Dietz</em></li>
                                 <li>Star Wars: Revenge of the Sith<em> - Matthew Stover</em></li>
                                 <li>Star Wars: A New Hope<em>- Ryder Windham</em></li>
                                 <li>Star Wars: Heir to the Empire<em> - Timothy Zahn</em></li>
                                 <li>A Game of Thrones<em> - George R.R. Martin</em></li>
                                 <li>Nineteen Eighty Four<em> - George Orwell</em></li>
                                 <li>Death Note 1 & 2 <em>- Tsunami Ohba & Takeshi Obata</em></li>
                                 </ul>
                                 <br />
                                 <h3>Fiction (Classics):</h3>
                                 <ul>
                                    <li>Paradise Lost <em>- John Milton</em></li>
                                    <li>Heart of Darkness <em>- Joseph Conrad</em></li>
                                    <li>Divine Comedy <em>- Dante</em></li>
                                 </ul>
                                </section>

                                <br /><br />
                                <h2>History</h2>
                                <hr />

                                <section className='li-book'>
                                 <h3>History (World War II):</h3>
                                 <ul>
                                 <li>Stalin: The Court of the Red Tsar<em> - Simon Sebag Montefiore</em></li>
                                 <li>Mussolini: A New Life<em> - Nicholas Farrell</em></li>
                                 <li>Bloodlands<em></em></li>
                                 <li>Strange Victory<em> - Ernest R. May</em></li>
                                 <li>The End<em> - Ian Kershaw</em></li>
                                 <li>To Hell With Tanks!<em> - Ken Tout</em></li>
                                 <li>In Rommel’s Backyard<em> - Alastair Timpson</em></li>
                                 <li>Gunners Moon<em> - John Bushby</em></li>
                                 <li>Iron Hulls, Iron Hearts<em> - Ian W. Walker</em></li>
                                 <li>Persuading the People<em> - David Welch</em></li>
                                 <li>German secret weapons: Blueprint for Mars <em> - Brain Ford</em></li>
                                 <li>The Phantom Major <em>- Virginia Cowles</em></li>
                                 <li>Popski’s Private Army <em>- Vladimir Peniakoff</em></li>
                                 </ul>
                                </section>

                                <section className='li-book'>
                                 <h3>History (XIX-XX Century):</h3>
                                 <ul>
                                    <li>Talk of the Devil<em> - Riccardo Orizio</em></li>
                                    <li>Always with Honour<em> - Pyotr Wrangel</em></li>
                                    <li>Guerrilla Warfare <em>- Che Guevara</em></li>
                                    <li>Great Britain’s Great War <em>- Jeremy Paxman</em></li>
                                    <li>The Fall of Yugoslavia <em> - Misha Glenny</em></li>
                                    <li>The Crimean War <em>- James Barbary</em></li>
                                    <li>Sink The Belgrano <em>- Mike Rossiter</em></li>
                                    <li>Napoleon: The Spirit of The Age <em>- Michael Broers</em></li>
                                    <li>Ekaterinburg: The Last Days of the Romanovs <em>- Helen Rappaport</em></li>
                                    <li>Bergoglio's List <em>- Nello Scavo</em></li>
                                    <li>The Russian Civil War <em>- Evan Mawdsley</em></li>
                                    <li>A King’s Story <em>- H.R.H. Edward VIII</em></li>
                                    <li>The Mint <em>- T.E. Lawrence</em></li>
                                 </ul>
                                </section>

                                <section className='li-book'>
                                 <h3>History (Other):</h3>
                                 <ul>
                                    <li>The Saxon and Norman Kings <em> - Christopher Brooke</em></li>
                                    <li>Kings and Queens of England <em> - Eric R. Delderfield</em></li>
                                    <li>The Coming of the French Revolution <em>- Georges Lefebvre</em></li>
                                    <li>English Revolts <em>- Bonamy Dobree</em></li>
                                 </ul>
                                 </section>


                                 <br /><br />
                                <h2>Philosophy, Theology, Politics and Economics</h2>
                                <hr />

                                <section className='li-book'>
                                 <h3>Philosophy:</h3>
                                 <ul>
                                 <li>Five Dialogues <em>- Plato</em></li>
                                 <li>The Republic <em>- Plato</em></li>
                                 <li>The Nicomachean Ethics <em>- Aristotle</em></li>
                                 <li>Politics <em>- Aristotle</em></li>
                                 <li>Beyond Good and Evil <em>- Friedrich Nietzsche</em></li>
                                 <li>Thus Spoke Zarathustra <em>- Friedrich Nietzsche</em></li>
                                 <li>Meditations <em>- Marcus Aurelius</em></li>
                                 <li>Art of War <em>- Sun Tzu</em></li>
                                 <li>Selected Philosophical Writings <em>- Aquinas</em></li>
                                 <li>Sofie’s World <em>- Jostien Gaarder</em></li>
                                 </ul>
                                </section>

                                <section className='li-book'>
                                 <h3>Modern Politics / Economics:</h3>
                                 <ul>
                                 <li>In Defence of Lost Causes <em>- Savoj Zizek</em></li>
                                 <li>Wealth of Nations <em>- Adam Smith</em></li>
                                 <li>Rights of Man <em>- Thomas Paine</em></li>
                                 <li>Common Sense <em>- Thomas Paine</em></li>
                                 <li>Leviathan <em>- Thomas Hobbes</em></li>
                                 <li>The Communist Manifesto <em>- Karl Marx & Friedrich Engels</em></li>
                                 <li>Das Kapital <em>- Karl Marx</em></li>
                                 <li>A Political History of The World <em>- Jonathan Holslag</em></li>
                                 </ul>
                                </section>

                                <section className='li-book'>
                                    <h3>Theology / Religious Texts:</h3>
                                    <ul>
                                    <li>Mere Christianity<em> - C. S. Lewis</em></li>
                                    <li>The Case for Religion<em> - Keith Ward</em></li>
                                    <li>The Orthodox Church <em>- Timothy Ware</em></li>
                                    <li>The Corpus Hermeticum</li>
                                    <li>The Gnostic Gospels</li>
                                    <li>ESV Study Bible</li>
                                    <li>Orthodox Study Bible</li>
                                    <li>Russian/English New Testament</li>
                                    <li>Common Worship</li>
                                    <li>The Kybalion</li>
                                    <li>Молитвослов <em> - Джорданвилль</em></li>
                                    </ul>
                                </section>




                                <section className='li-book'>
                                    <h3>Science / Maths:</h3>
                                    <ul>
                                    <li>Usbourne Illustrated Dictionary of Science</li>
                                    <li>Dinosaurs and Prehistoric Life</li>
                                    <li>A Brief History of Time <em>- Stephen Hawking</em></li>
                                    <li>Things to Make and Do in the 4th Dimension <em>- Matt Parker</em></li>
                                    </ul>
                                </section>



               



                                <br /><br />
                                <h2>Language</h2>
                                <hr />

                                <section className='li-book'>
                                    <h3>Language Learning and Linguistics</h3>
                                    <ul>
                                    <li>Lost Languages</li>
                                    <li>Chomsky - Smith and Allott</li>
                                    <li>Reading Koine Greek</li>
                                    <li>Russian Basic Grammar</li>
                                    <li>Penguin Russian Course <em>- Penguin</em></li>
                                    <li>Remembering The Traditional Hanzi 1 <em>- James Heisig</em></li>
                                    <li>Remembering The Traditional Hanzi 2 <em>- James Heisig</em></li>
                                    <li>Short Stories in Russian for Beginners <em>- Olly Richards & Alex Rawlings</em></li>
                                        <li>Short Stories in Russian (intermediate) <em>- Olly Richards</em></li>
                                    </ul>
                                </section>

                                <section className='li-book'>
                                    <h3>Russian Langauge Books:</h3>
                                    <ul>
                                        <li>Код Да Винчи <em>- Дэн Браун</em></li>
                                        <li>Москва, я не люблю тебя <em>- Сергей Минаев</em></li>
                                        <li>Изолированная Страна <em>- И.И. Шарифжанов </em></li>
                                        <li>Гарри Поттер И философский камень <em>- ДЖ. К. Роулинг </em></li>
                                        <li>Метро 2033 <em>- Дмитрии Глуховский</em></li>
                                        <li>Метро 2034 <em>- Дмитрии Глуховский</em></li>
                                    </ul>
                                </section>

                                <section className='li-book'>
                                    <h3>Chinese Langauge Books:</h3>
                                    <ul>
                                    <li>西游記</li>
                                    <li>三國演義</li>
                                    <li>水滸傳</li>
                                    <li>紅樓夢</li>
                                    </ul>
                                </section>

                                <br /><br />
                                <h2>Other</h2>
                                <hr />

                                <section className='li-book'>
                                <h3>Children's Books:</h3>
                                 <ul>
                                 <li>The BFG <em>- Roald Dahl</em></li>
                                 <li>The Last Gold Diggers <em>- Harry Horse</em></li>
                                 <li>The Last Cowboys <em>- Harry Horse</em></li>
                                 <li>The Last Polar Bears <em>- Harry Horse</em></li>
                                 <li>The Last Castaways <em>- Harry Horse</em></li>
                                 </ul>
                                </section>

                                <section className='li-book'>
                                    <h3>Video Game Guides:</h3>
                                    <ul>
                                    <li>Halo 2 Official Guide</li>
                                    <li>Halo 3 Official Guide</li>
                                    <li>Halo Reach Official Guide</li>
                                    <li>Unreal Tournament Official Guide</li>
                                    </ul>
                                </section>

                                <section className='li-book'>
                                    <h3>Other / Unsorted:</h3>
                                    <ul>
                                    <li>The Greatest Trade Ever <em>- Gregory Zuckerman</em></li>
                                    <li>Usbourn’s Guide to Animal Tracks and Signs</li>
                                    <li>Bible for Minecrafters</li>
                                    <li>Meal in a Mug</li>
                                    <li>Card Game Encyclopaedia</li>
                                    </ul>
                                </section>

                                <button className="btn readMore" onClick={() => {history(-1); props.toggleToTop()}}>
                                    <i className="fas fa-arrow-left"></i> Go Back
                                </button>
                            </div>
                        </div>
                    } 
                    {loading ? null : <Sidebar/>}
                </div>
                 }
                <Footer toggleToTop={props.toggleToTop} />
            </main>
        </div>
    )
}