import React from 'react';

import {Link} from 'react-router-dom';

const Footer = (props) => {

    const redirect = url => window.location = url;

    return (
        <footer>

            
            <div className="bottom-links">
                   
                        <ul className="homepage-quick-links bottom-links">
                                <li><Link onClick={props.toggleToTop} to="/posts"><i className="fas fa-pen"></i> Blog</Link></li>
                                {/* <li><Link to="/blog/projects"><i className="fas fa-project-diagram"></i> Projects</Link></li> */}
                                <li><Link onClick={props.toggleToTop} to="/portfolio"><i className="far fa-gem"></i> Portfolio</Link></li>
                                {/* <li><a href="/rss"><i className="fas fa-rss"></i> RSS</a></li> */}
                                <li><Link onClick={props.toggleToTop} to="/contact"><i className="fas fa-envelope-square"></i> Contact</Link></li>
                                {/* <li><a href="https://github.com/jimjam117"><i className="fab fa-github"></i> Github</a></li> */}
                            </ul>
                        </div>

                    
                    <hr />


            <div className="footer-email">jamessparrow101@googlemail.com</div>
                        <ul className="homepage-contact-links">
            <li>
                <a href="https://linkedin.com/in/james-sparrow-783137286" style={{backgroundColor: '#0a66c2', color: 'white'}}>
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://github.com/jimjam117" style={{backgroundColor: '#24292f', color: 'white'}}>
                  <i className="fab fa-github"></i>
                </a>
              </li>
              <li>
                <a href="https://gitlab.com/jimjam117" style={{backgroundColor: '#4e3e7d', color: '#fc7735'}}>
                  <i className="fab fa-gitlab"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/jimjamleman/" style={{backgroundColor: '#cb297c', color: 'white'}}>
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="/rss" style={{backgroundColor: '#de780c', color: 'white'}}>
                  <i className="fas fa-rss"></i>
                </a>
              </li>
              <li>
                <a href="https://discordapp.com/users/252471185864916992" style={{backgroundColor: '#5865f2', color: 'white'}}>
                  <i className="fab fa-discord"></i>
                </a>
              </li>
          </ul>

<br />
        </footer>
   
    );
}

export default Footer;