import React, {useState, useEffect} from 'react'

import { Link } from "react-router-dom";

import Header from "./partials/Header";
import Footer from "./partials/Footer";

// import ReCAPTCHA from "react-google-recaptcha";

const Contact = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [body, setBody] = useState("");
  const [captcha, setCaptcha] = useState();

  function onChange(value) {
    setCaptcha(value);
  }

  useEffect(() => {
    document.title =  "Contact | Jsparrow.uk" ;
  });

  return (
    <div className="main-container">
    <Header toggleTheme={props.toggleTheme}/>

      <main>
        <div className="homepage-content">
          <div>
            <h2>Contact</h2>
            <div className="homepage-email-msg">
              <h3>For serious business, please email:</h3>
              <h4>jamessparrow101@googlemail.com</h4>
              <br />
              <h3>Or you can contact me on Linkedin:</h3>
              <ul className="homepage-quick-links">
                <li>

                <a href="https://linkedin.com/in/james-sparrow-783137286">james-sparrow-783137286</a>
                </li>
              </ul>
            </div>
            <hr />
            <br /><br />
            <h4>Other Socials:</h4>
            <ul className="homepage-quick-links">
              <li>
                <a href="https://github.com/jimjam117">
                  <i className="fab fa-github"></i> Github
                </a>
              </li>
              <li>
                <a href="https://gitlab.com/jimjam117">
                  <i className="fab fa-gitlab"></i> Gitlab
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/jimjamleman/">
                  <i className="fab fa-instagram"></i> Instagram
                </a>
              </li>
              <li>
                <a href="https://steamcommunity.com/id/JimJam117">
                  <i className="fab fa-steam"></i> Steam
                </a>
              </li>
              <li>
                <a href="https://discordapp.com/users/252471185864916992">
                  <i className="fab fa-discord"></i> Discord
                </a>
              </li>
              {/* <li><a href="https://twitter.com/jimjamethon"><i className="fab fa-twitter"></i> Twitter</a></li> */}
            </ul>
          </div>
        </div>

        <br />
        <br />
        <br />

        <Footer toggleToTop={props.toggleToTop} />
      </main>
    </div>
  );
};

export default Contact;
