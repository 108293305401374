import React, {useState, useEffect} from 'react';

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

import {Link} from 'react-router-dom';
import apiUrlBase from './config/apiUrlBase';

import Header from './partials/Header';
import Search from './partials/Search';
import Footer from './partials/Footer';
import LatestPost from './partials/LatestPost';
import LatestPosts from './partials/LatestPosts';

const Home = (props) => {

    const [searchDisplay, setSearchDisplay] = useState(false);
    const setDisplay = (input) => setSearchDisplay(input);

    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [categories, setCategories] = useState([]);



    const fetchItems = async (signal , apiUrl = `${apiUrlBase}/category`) =>  {
        
        // console.log("load");
        // console.log(apiUrl, {signal});
        try {
            const req = await fetch(apiUrl, {
                method: "GET",
                signal: signal,
                headers : { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }})
                .then(async (response) => {
                    
                    //throw errors if issues
                    if (response.status === 500) {
                        // console.log("500");
                    }
                    else if(response.status === 404) {
                        // console.log("404");
                    }
                    else if(response.status === 419) {
                        // console.log("419");
                    }
                    else if (response.status !== 400) {
                        // console.log(response.status);
                    }
    
                    const data = await response.json();

                    setResults(data);
                    // console.log(data);
                    setCategories(data);
                    setLoading(false);
            })
        }
        catch(error) {

            // console.log("OKAY I ERROR AND LOAD IS "+loading)
            // console.log("Error ", error);
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchItems(signal);
      
        return () => {
          controller.abort(); 
        };
      }, []);

      useEffect(() => {
        document.title = "James Sparrow | jsparrow.uk";
      });

    return (
        <div className="main-container">
            <Header toggleTheme={props.toggleTheme}/>
        <main>
            {/* <Search display={searchDisplay} setDisplay={setDisplay}/> */}
            {searchDisplay ? null : 
            <div>
                    <div className="banner"></div>
                    <div className="homepage-content">
                        <div className="homepage-left">
                            <img className="homepage-profile-pic-top" src="/jimjam_aug24.webp" alt="Me" loading="lazy"/>
                            {/* <p> */}
                                {/* <img className="homepage-profile-pic" src="/img/jimjam3.png" alt="Me" /> */}
                            {/* </p> */}
                   
                            <h1>Bonjour</h1>
                            <br/>
                            <p>I'm James and this is my website.</p>
                            <br/>
                            <p>
                            I work as a software developer in London, having recently completed my bachelor’s degree studying computer science at UEA (University of East Anglia), although I started my degree at Aston University in Birmingham. Aside from software, I’m generally interested in linguistics, philosophy and language.  </p>
                            <br/>
                            <p>Here you’ll find a record of personal projects I’ve worked on, as well as any other ramblings I decide are acceptable enough to make public.  </p>
                             
                        </div>
                        <div className="homepage-right">
                            <img className="homepage-profile-pic" src="/jimjam_aug24.webp" alt="It's me" />
                            {/* <em><Link className="unlinkStyle" to="/post/pics">More pics of meh</Link></em>
                             */}
                        </div>
                    </div>
                    <div className="homepage-content">
                    <div>
                            <h2>Projects</h2>
                            <hr />
                            <br />
                            <ul className="homepage-quick-links">
                                <li><a href="https://hanzibase.net"><i className="icon hanzibase-icon"></i> Hanzibase: </a>
                                 A web application database of Chinese characters, made with <span style={{textDecoration: 'line-through'}}>Laravel and</span> React.</li>
                                <li><a href="/category/4"><i className="icon hk47-icon"></i> Hong Kong 47</a><strong>:</strong> <span>
                                Doom/Wolfenstien style first person shooter, developed in Unity.
                                    </span></li>
                                <li><Link onClick={props.toggleToTop} to="/category/5">🎓 Uni Projects: </Link>
                                 <span> </span> See all the projects I have done at Uni.</li>

                            </ul>
                            <br />
                            <p>... and more to come!</p>
                            <br/>
                            <h2>Other Stuff</h2>
                            <hr />
                            <br />
                            <ul className="homepage-quick-links">
                                {/* <li><Link onClick={props.toggleToTop} to="/bookshelf">📚 Bookshelf: </Link>
                                 <span> </span> My personal library.</li> */}
                            
                                <li><Link onClick={props.toggleToTop} to="/resources">🪄 Resources and tools </Link>
                                 <span> </span> I either use or have used that have been helpful in my various persuits.</li>
                            </ul>
                            <br />
                        </div>

                        

                        <div className='homepage-blogposts'>
                        <h2>Blog Posts</h2>
                        <hr />
                        <br />
                            <LatestPosts toggleToTop={props.toggleToTop}></LatestPosts>                          
                        </div>

                    </div>
                    <div className='homepage-content'>
                        <div className='homepage-topics'>
                        <h2>Topics</h2>
                        <hr />
                        <ul className="homepage-topic-links">
                            {loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> : 
                            categories.map((category) => {
                                return (
                                    <li key={category.id} >
                                    <Link onClick={props.toggleToTop} to={"/category/" + category.id} dangerouslySetInnerHTML={{__html: category.emoji_name}}>
                                       
                                    </Link>
                                    </li>
                                )
                            })
                            }
                            </ul>
                        </div>
                        </div>                      
                        
                 
            </div>
            }
            <Footer toggleToTop={props.toggleToTop} />
        </main>
        </div>
    );
}

export default Home;