import React, {useState, useEffect} from 'react'

import {useNavigate} from 'react-router-dom'
import Header from './partials/Header';
import Footer from './partials/Footer';
import Search from './partials/Search';
import Sidebar from './partials/Sidebar';
import ReactHtmlParser from 'html-react-parser'; 

//import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import apiUrlBase from './config/apiUrlBase';

export default function Category(props) {

  let history = useNavigate();

    // search state
    const [searchDisplay, setSearchDisplay] = useState(false);
    const setDisplay = (input) => setSearchDisplay(input);

    // post state
    const [state, setState] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchItem = async (
        signal,
        apiUrl = `${apiUrlBase}${window.location.pathname}`
      ) => {
        // console.log("load");
        // console.log(apiUrl, { signal });
        try {
          const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }).then(async (response) => {
            //throw errors if issues
            if (response.status === 500) {
              // console.log("500");
            } else if (response.status === 404) {
              // console.log("404");
            } else if (response.status === 419) {
              // console.log("419");
            } else if (response.status !== 400) {
              // console.log(response.status);
            }
    
            const data = await response.json();
            setState(data)
            setLoading(false)
            
          });
        } catch (error) {
          // console.log("OKAY I ERROR AND LOAD IS " + loading);
          // console.log("Error ", error);
        }
      };
    
      useEffect(() => {
        const controller = new AbortController();
        fetchItem(controller.signal);
    
        return () => {
          controller.abort();
        };
      }, []);

      useEffect(() => {
        document.title = state.category ? state.category.title + " | Jsparrow.uk" : "Jsparrow.uk";
      });
    
    return (
        <div className="main-container">
        <Header toggleTheme={props.toggleTheme}/>

            <main>
            {/* <Search currentId={props.match.params.id} display={searchDisplay} setDisplay={setDisplay}/> */}
            
            {searchDisplay ? null : 
                <div className="container">
                {loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> :
                        <div className="post">
                           {
                            state.category.image !== null ? <img className="post_thumbnail" src={state.category.image} alt={state.category.title}></img> : <></>}
                            <div className="post_container">
                                <div className="timestamp">{state.category.created_at}</div>
                                <h1>{state.category.title}</h1>
                                {state.category.body !== null && ReactHtmlParser(state.category.body)}

                                <br /><br />
                                <hr />

                                {/* if there are no posts in this category, display an empty div */}
                                {/* Else, we print out a list of posts with links to them */}
                                {state.category_posts && state.category_posts.length === 0 ? <div></div> 
                                : <div>
                                    <br />
                                    <em>List of related posts:</em>
                                    <ul>
                                    {state.category_posts.map((post) => {
                                        return ( <li key={post.id}><Link onClick={props.toggleToTop} to={"/post/" + post.slug}>{post.title}</Link></li> )
                                    })}
                                    </ul>
                                    <br />
                                    <hr />
                                    </div> }
      
                                    
                                

                                    <button className="btn readMore" onClick={() => {history(-1); props.toggleToTop()}}>
                                    <i className="fas fa-arrow-left"></i> Go Back
                                </button>
                            </div>
                        </div>
                    } 
                    {loading ? null : <Sidebar recent={state.recent_post}/>}
                </div>
                 }
                <Footer toggleToTop={props.toggleToTop} />
            </main>
        </div>
    )
}
