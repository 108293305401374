import React, {useState, useEffect, useLayoutEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Header from './partials/Header';
import Footer from './partials/Footer';
import Search from './partials/Search';
import Sidebar from './partials/Sidebar';
import ReactHtmlParser from 'html-react-parser'; 

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import apiUrlBase from './config/apiUrlBase';

export default function Resources(props) {

    let history = useNavigate();
// search state
const [searchDisplay, setSearchDisplay] = useState(false);
const setDisplay = (input) => setSearchDisplay(input);

// post state
const [post, setPost] = useState({});
const [category, setCategory] = useState({});
const [loading, setLoading] = useState(false);

useLayoutEffect(() => {
    // window.scrollTo(0, 0)
});

const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
    
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                
                //throw errors if issues
                if (response.status === 500) {
                    // console.log("500");
                }
                else if(response.status === 404) {
                    // console.log("404");
                }
                else if(response.status === 419) {
                    // console.log("419");
                }
                else if (response.status !== 400) {
                    // console.log(response.status);
                }

                const data = await response.json();
                setPost(data);

                if (!data.category_id) {
                    setLoading(false);
                }
                const categoryReq = await fetch(apiUrlBase + "/category/" + data.category_id, {
                    method: "GET",
                    signal: signal,
                    headers : { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }}).then (async (response) => {
                        // console.log("well we got here I guess")
                        const data = await response.json();
                        setCategory(data.category)
                        setLoading(false);
                    })


               
        })
    }
    catch(error) {

        // console.log("OKAY I ERROR AND LOAD IS "+loading)
        // console.log("Error ", error);
    }
}

useEffect(() => {
    const controller = new AbortController();
    fetchItem(controller.signal);
  
    return () => {
      controller.abort(); 
    };
  }, []);

  useEffect(() => {
    document.title = post.title ? post.title + " | Jsparrow.uk" : "Jsparrow.uk";
  });


    return (
        <div className="main-container"> 
            <Header toggleTheme={props.toggleTheme}/>

            <main>
            {/* <Search currentId={props.match.params.id} display={searchDisplay} setDisplay={setDisplay}/> */}
            
            {searchDisplay ? null : 
                <div className="container">
                {loading ? <div className="spinner"><ClipLoader color='#356f2a'/></div> :
                        <div className="post">
                            <div className="post_container resource-container">
                                <h1>Resources and Tools</h1>
                                <section>
                                    <p>I thought it would be a good idea to list some of the resources and tools I've used over the years. You can find quick links to the different sections below.</p>
                                    <p>I also <Link to='/people'>have a page for other cool people</Link> who couldn't fit on this page.</p>
                                </section>
                                <section>
                                    <ol>
                                        <li><a href="#computer">Computer Stuff</a></li>
                                        <ol className='sublist'>
                                            <li><a href="#programmingTutorials">Programming Tutorials</a></li>
                                            <li><a href="#software">Software</a></li>
                                            <li><a href="#programmingGeneral">Languages / Frameworks I've Used</a></li>
                                        </ol>
                                        {/* <li><a href="#linux">Linux</a></li>
                                        <ol className='sublist'>
                                            <li><a href="#linuxDistros">Linux Distro</a></li>
                                            <li><a href="#linuxSoftware">Linux Programs</a></li>
                                        </ol> */}
                                        <li><a href="#lang">Language Learning</a></li>
                                        <ol className='sublist'>
                                            <li><a href="#langGeneral">General Language Learning</a></li>
                                            <li><a href="#langRus">Learning Russian 🇷🇺</a></li>
                                            <li><a href="#langChi">Learning Chinese 🇨🇳</a></li>
                                        </ol>
                                    </ol>
                                </section>
                                <br />
                                <br />
                                <h1 id='computer'>Computer Stuff</h1>
                                <h2 id='programmingTutorials'>Programming Tutorials</h2>
                                <hr />
                                <section>
                                    <img src="/img/resources_tools/sentdex.jpeg" alt="sentdex" />
                                    <h3>pythonprogramming.net / sentdex</h3> 
                                    Website: <a href='https://pythonprogramming.net/'>pythonprogramming.net</a>
                                    <br />
                                    Youtube: <a href="https://www.youtube.com/sentdex">sentdex</a>
                                    <p>
                                    Harrison Kinsley's Python tutorial website. Some very useful guides, the guy has a great youtube channel as well.
                                    </p>
                                </section>

                                <section>
                                    <img src="/img/resources_tools/w3.png" alt="w3schools" />
                                    <h3>W3Schools</h3> Link: <a href='https://www.w3schools.com/'>www.w3schools.com</a>
                                    <p>
                                    Best place to start learning how to make websites. Start with HTML, then move onto CSS, Javascript, and see where it takes you! On top of having some great courses for learning, w3schools is also a great reference if you forget something along the way.
                                    </p>
                                </section>

                                <section>
                                    <img src="/img/resources_tools/traversy.png" alt="Traversy Media" />
                                    <h3>Traversy Media</h3>
                                    Link (Youtube): <a href="https://www.youtube.com/@TraversyMedia">TraversyMedia</a>
                                    <p>
                                    When I was first starting out with learning web development, Traversy Media’s youtube channel was a godsend. They have hour+ long courses on many web programming topics, all for free on their Youtube channel. Beats paying for Udemy courses!
                                    </p>
                                </section>

                                <section>
                                    <img src="/img/resources_tools/freecodecamp.png" alt="FreeCodeCamp" />
                                    <h3>FreeCodeCamp</h3>
                                    Link: <a href="https://www.freecodecamp.org/learn">freecodecamp.org</a>
                                    <p>
                                    Offers a wide range of interactive learning, starting from making basic websites and taking you all the way to advanced topics like machine learning. They also have a great code interview prep course where you go over common data structures / algorithms questions that employers will want you to know off the top of your head.
                                    </p>
                                </section>


                                <section>
                                    <img style={{"border": "none"}} src="/img/resources_tools/rustacean-flat-happy.png" alt="Rust" />
                                    <h3>Rust Book</h3>
                                    Link: <a href="https://doc.rust-lang.org/stable/book/ch00-00-introduction.html">Rust Book Online</a>
                                    <p>
                                        If you're thinking of learning <a href="https://www.rust-lang.org">Rust</a>, I found the Rust book (available to view for free on the main website) to be very useful. It explains the oddities of Rust in a comfortable progression from similar topics to more complex ones. You can also purchase a physical copy of the book too.
                                    </p>
                                </section>
               
                                <h2 id='software'>Software</h2>
                                <hr />
                                <section>
                                    <img src="/img/resources_tools/ubuntu.png" alt="Ubuntu" />
                                    <h3>Ubuntu Minimal Install</h3> Link: <a href='https://help.ubuntu.com/community/Installation/MinimalCD'>help.ubuntu.com/community/Installation/MinimalCD</a>
                                    <p>
                                    Past versions of Ubuntu used to be available via a handy minimal install CD, called mini.iso. I used to prefer this due to the fact you could choose a couple pre-set desktop environments to be installed during the setup process, but unfortunately this iso isn’t available for newer versions of Ubuntu (the latest being 18.04). You can still install 18.04 and then upgrade to a new version, or simply <a href='https://ubuntu.com/download/server'>use the server iso</a> if you want an up-to-date minimal installation.
                                    </p>
                                </section>

                                <section>
                                    <img src="/img/resources_tools/suckless.png" alt="suckless" />
                                    <h3>Suckless Software (DWM and ST)</h3> Link: <a href='https://suckless.org'>suckless.org</a>
                                    <p>
                                        Suckless is a group of developers who specialise in simple lightweight alternatives to some larger, more bloated open source programs. Suckless software is well known for being minimal and super efficient, usually written in C. They have strict standards they hold their codebase to.
                                    </p>
                                    <p>
                                        I mostly use <a href="https://dwm.suckless.org">DWM</a> (their window manager) and <a href="https://st.suckless.org">ST</a> (their terminal application). Both are very minimal but have patches available to add in the functionality you desire. Instead of having a config file like larger programs, you need to recompile the source code in order to add these patches, essentially rebuilding the program when you want to make a change. I might end up uploading my builds here at some point.
                                    </p>
                                </section>

                                <section>
                                    <img src="/img/resources_tools/paintnet.png" alt="Paint.NET" />
                                    <h3>Paint.NET (Windows only)</h3> Link: <a href='https://www.getpaint.net/index.html'>getpaint.net</a>
                                    <p>
                                    Paint.NET is a superb image editing program. It hits the perfect balance between functionality and simplicity, it’s not a massive piece of software like Photoshop but allows for alpha transparency, layers and other convenient features not present in “simple” paint-like programs. I've used Paint.NET <em>a lot</em> over the years, all of the artwork for Hk47 and my other games was made in it.
</p><p>
Frustratingly, Paint.NET is only available on windows (hence the .NET). There is a linux alternative called <a href="https://www.pinta-project.com/">Pinta</a>, but unfortunately this software has never worked for me, it’s always been really unstable and prone to random crashes <em>(not what you want when working on an image for ages)</em>. It may sound silly, but using Paint.NET for my game art is one of the main reasons I keep a Windows partition on my PC.

                                    </p>
                                </section>

                 
                                <h2 id='programmingGeneral'>Languages / Frameworks I've Used</h2>
                                <hr />
                                <section>
                                    {/* <h3>Languages / Frameworks I've Used</h3> */}
                                    {/* <br /> */}
                                    <ul className='resource-ul-no-style'>
                                        <li>
                                            <h3>C# (with Unity)</h3>
                                            <p>
                                                C# was the first language I learned. I have used it most recently with <a href="https://unity.com/">Unity</a> on projects such as HK47.
                                            </p>
                                        </li>
                                        <li>
                                            <h3>C / C++</h3>
                                            <p>
                                                I've mostly used C and C++ on Uni coursework and projects such as
                                                <a href="/post/embedded_year2"> this one</a>.
                                            </p>
                                        </li>
                                        <li>
                                            <h3>PHP (Laravel)</h3>
                                            <p>
                                                <a href="https://www.php.net/">PHP</a> was the first language this website was written in <a href="/post/the-php-blog-and-basic-cms">way back in 2017</a> (God looking at that makes me feel old). Whilst I loved PHP when I was younger, I've tried my best to avoid it nowadays. I used to make websites like this one and london24racing in pure PHP, but most of my later work was in the <a href="https://laravel.com/">MVC Laravel framework</a>.
                                            </p>
                                        </li>
                                        <li>
                                            <h3>JavaScript</h3>
                                            <p> I have used a couple different JavaScript frameworks for projects at Uni, work and for my personal projects at this point.</p>
                                            <p>As of writing this at least, the frontend of this website is written in <a href="https://react.dev/">React</a>. React allows you to create single page webapps, which (in theory) provide a much nicer user experience than a standard website, as well as making programmatic generation of content on the user’s end easier. In my earlier projects I exclusively used React, but in other project’s I’ve worked on more recently at Uni / Work <a href="https://vuejs.org">Vue.JS</a> seems to be the preferred choice.</p>
                                            <p>In terms of backend development, I have mostly used <a href="https://expressjs.com">Express.js</a>. Express is a very lightweight and extensible web framework that allows you to create scalable JS apps. It doesn’t have all the organisation and boilerplating of a fully featured web framework like Laravel, but there are more fully featured frameworks built on top of express such as <a href="https://nestjs.com">Nest.JS</a>, which I’ve also used and definitely prefer over Laravel nowadays.</p>
                                        </li>
                                        {/* <li>
                                            <h3>Rust</h3>
                                            <p></p>
                                        </li> */}
                                    </ul>
                                </section>



                                <br /><br />
                                <br />
                                <h1 id='lang'>Language Learning</h1>

                                <h2 id='langGeneral'>General Language Learning</h2>
                                <hr />
                                <section>
                                    <img src="/img/resources_tools/anki.png" alt="Anki" />
                                    <h3>Anki</h3> Link: <a href='https://apps.ankiweb.net'>apps.ankiweb.net</a>
                                    <p>
                                    Anki - Free flashcard program, complete with spaced-repetition system. Very useful for all languages (especially for vocabulary and sentences), but I've had the most use out of Anki from memorising Chinese Characters. Also generally useful for note taking / managing too, I've used Anki so much in Uni just for this.
                                    </p>
                                </section>
                                
                                <section>
                                    <img src="/img/resources_tools/lingq.png" alt="LingQ" />
                                    <h3>LingQ and Learning with Texts</h3> Link: <a href='https://www.lingq.com/en/'>lingq.com</a>
                                    <p>
                                        LingQ is a great (if pricey) web-based foreign language web reader. It allows you to read in a foreign language without worrying about constantly looking up unknown words in a dictionary, as you can see a translation for a word or phrase instantly. You can also rank words from 1 to 5 in terms of how familiar they are, which lets you track your progress.
                                    </p>
                                    
                                    <p>
                                        You probably shouldn't start learning a language with LingQ in my opinion, as it's all reading based, but once you have a grasp of the grammar LingQ can take you quite far in building vocabulary. 
                                    </p>
                                    
                                    <p>
                                        If you don't want to spend the money, there is a free, open source alternative called Learning With Texts (LwT), which <strong>at least at the time of writing</strong> you can find linked here: <a href="https://learning-with-texts.sourceforge.io/#preface">learning-with-texts.sourceforge.io</a>.
                                    </p>
                                    
                                    <p><em>
                                        (I specify at the time of writing because a few years ago, LwT mysteriously disappeared from the internet citing "legal disputes". As far as I know, it was never made public what this dispute was, but the general mood of the community placed the blame on LingQ. However, these legal issues must have been resolved, as the project seems to be back up online now.)
                                        </em></p>
                                    
                                    <p>
                                        Despite the fact LwT is free and open source, I still preferred to use LingQ due to the ease of access from multiple devices, the speed of the service, and most importantly, the translations. LingQ has a massive community who have been adding translations to words for ages, and LwT requires you to source these translations yourself, significantly slowing down the process. 
                                    </p>
                                </section>

                                <h2 id='langRus'>Learning Russian 🇷🇺</h2>
                                <hr />
                                <section>
                                    <img src="/img/resources_tools/russiangrammar.jpeg" alt="russiangrammar" />
                                    <h3>Tips4Russian (aka "Russian Grammar")</h3> 
                                    Website: <a href='https://www.tips4russian.com/'>tips4russian.com</a>
                                    <br />
                                    Youtube: <a href='https://www.youtube.com/@russiangrammar'>russiangrammar</a>
                                    <p>
                                    This is one of the best resources for Russian grammar revision. On his youtube channel Dr. Curtis Ford helps clarify the often confusing complexity of Russian grammar. There are videos for most grammatical concepts, usually with multiple examples and explanations for each. We played his videos many times in my Russian classes.
                                    </p>
                                </section>

                                <section>

                                    <img src="/img/resources_tools/russianwithmax.jpeg" alt="RussianWithMax" />
                                    <h3>Russian with Max</h3> 
                                    Website: <a href='https://www.russianwithmax.com/'>russianwithmax.com</a>
                                    <br />
                                    Youtube: <a href='https://www.youtube.com/@RussianWithMax'>RussianWithMax</a>
                                    <p>
                                    RussianWithMax has many videos with clear and easy to understand spoken Russian. He focuses on comprehensible input and TPRS. RussianWithMax's videos were some of the first Russian videos I could understand when listening without subtitles, so if you need listening material I’d highly recommend his channel. 
                                    </p>
                                </section>

                                <h2 id='langChi'>Learning Chinese 🇨🇳</h2>
                                <hr />
                                <section>

                                    {/* <img src="/img/resources_tools/chinesegrammar.png" alt="Chinese Grammar Wiki" /> */}
                                    <h3>The Chinese Grammar Wiki</h3> 
                                    Link: <a href='https://resources.allsetlearning.com/chinese/grammar/'>resources.allsetlearning.com/chinese/grammar</a>
                                    <p>
                                        A great resource especially when you first start off with Mandarin. Chinese grammar, despite what you might’ve guessed, is actually very straightforward and simple. So once you get the main concepts under your belt you can move onto the more difficult parts of the language pretty quickly (namely pronunciation and remembering the characters). Which is just as well, as these take a while in comparison.
                                    </p>
                                </section>


                                <button className="btn readMore" onClick={() => {history(-1); props.toggleToTop()}}>
                                    <i className="fas fa-arrow-left"></i> Go Back
                                </button>
                            </div>
                        </div>
                    } 
                    {loading ? null : <Sidebar/>}
                </div>
                 }
                <Footer toggleToTop={props.toggleToTop} />
            </main>
        </div>
    )
}