import React, {useState, useEffect} from 'react';

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

import {Link} from 'react-router-dom';
import apiUrlBase from '../config/apiUrlBase';


const LatestPosts = (props) => {

    const [searchDisplay, setSearchDisplay] = useState(false);
    const setDisplay = (input) => setSearchDisplay(input);


    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    //const [categories, setCategories] = useState([]);

    const today = new Date();

    const checkIfNew = (dateInput) => {
        const date = new Date(dateInput);
        if (today.getFullYear() === date.getFullYear()) {
            if (today.getMonth() === date.getMonth()) {
                return true;
            }   
        }
        
        return false;
    }


    const fetchItems = async (signal, apiUrl = `${apiUrlBase}/post?page=${1}`) =>  {
        // console.log("load");
                await fetch(apiUrl, {
                    method: "GET",
                    signal: signal,
                    headers : { 
                      'Content-Type': 'text/html',
                      'Accept': 'text/html'
                   }})
                    .then(async (response) => {
                        
                        //throw errors if issues
                        if (response.status === 500) {
                            // console.log("500");
                        }
                        else if(response.status === 404) {
                            // console.log("404");
                        }
                        else if(response.status === 419) {
                            // console.log("419");
                        }
        
                        const data = await response.json();

                        // console.log(currentPage);
                        // setResults(data);

                        //setCurrentPage(data.posts.current_page);
                        //setLastPage(data.posts.last_page);

                        setPosts(data.posts);
                        setLoading(false);
                })
            }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchItems(signal);
        
        return () => {
            controller.abort(); 
        };
        }, []);
    



    return (
        
        <>
                                <ul className="homepage-quick-links">
                            {loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> : 

                            posts.map((post) => { 
                                return <li key={post.id}>
                                    {checkIfNew(post.createdAt) ? <span>⭐️ <span style={{"fontSize": "small", "fontWeight": "bold"}}>NEW! </span></span> : <></>}
                                    <Link onClick={props.toggleToTop} to={"/post/" + post.slug}>{post.title}</Link></li>
                            })}
                            <li style={{listStyle: 'none'}}> <Link to={"/posts"}><em>...view more</em></Link></li>
                            
                            </ul>

      
        </>

                            
                        

    );
}

export default LatestPosts;