import React from "react";
import ReactDOM from "react-dom";
import "../css/styles.css";
import "../css/light.css";
import "../css/dark.css";
import { useState, useEffect, useLayoutEffect } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "./Home";
import Blog from "./Blog";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import Single from "./Single";
import Category from "./Category";
import Categories from "./Categories";
import Rss from "./Rss";
import Resources from "./Resources";
import CoolPeople from "./CoolPeople";
import Bookshelf from "./Bookshelf";

function App() {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    if (theme === "light") {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  };

  const [toTop, setToTop] = useState(false);
  const toggleToTop = () => {
    setToTop(!toTop);
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    const mreq = window.matchMedia("(prefers-color-scheme: dark)");

    if (mreq.matches) {
      setTheme("dark");
    }

    mreq.addEventListener("change", (e) => {
      if (e.matches) {
        setTheme("dark");
      } else {
        setTheme("light");
      }
    });
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, -99);
    setToTop(false);
  }, [toTop]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Navigate to="/home" />} />
          <Route
            path="/home"
            element={
              <Home toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/portfolio"
            element={
              <Portfolio toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/posts"
            element={
              <Blog toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/categories"
            element={
              <Categories toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route path="/rss" element={<Rss />} />
          <Route
            path="/resources"
            element={
              <Resources toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/people"
            element={
              <CoolPeople toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/bookshelf"
            element={
              <Bookshelf toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />

          {/* <Route path="/search/:query" element={SearchResults}/> */}

          <Route
            path="/category/:id"
            element={
              <Category toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />
          <Route
            path="/post/:id"
            element={
              <Single toggleToTop={toggleToTop} toggleTheme={toggleTheme} />
            }
          />

          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
if (document.getElementById("app")) {
  ReactDOM.render(<App />, document.getElementById("app"));
}
