import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
//import ReactHtmlParser from 'html-react-parser';
import Header from './partials/Header';
import Footer from './partials/Footer';
import Search from './partials/Search';
import Sidebar from './partials/Sidebar';

//import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import apiUrlBase from './config/apiUrlBase';

const Blog = (props) => {


    const [searchDisplay, setSearchDisplay] = useState(false);
    const setDisplay = (input) => setSearchDisplay(input);


    // abort controller
    var controller = new AbortController();
    var signal = controller.signal;


    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);
    const [posts, setPosts] = useState([]);
    const [categories, setCategories] = useState([]);

    // pagination state
    const [currentPage, setCurrentPage] = useState();
    const [lastPage, setLastPage] = useState();

    // pagination function
    const changePage = (pageToChangeTo) => {
        if(pageToChangeTo < 1 || pageToChangeTo > lastPage){
            // console.log("Page to change to: " + pageToChangeTo + " is not within boundries");
        }
        else {
            setCurrentPage(pageToChangeTo);
            setLoading(true);
        }
    }

    const fetchItems = async (signal, apiUrl = `${apiUrlBase}/post?page=${currentPage}`) =>  {
        // console.log("load");
                await fetch(apiUrl, {
                    method: "GET",
                    signal: signal,
                    headers : { 
                      'Content-Type': 'text/html',
                      'Accept': 'text/html'
                   }})
                    .then(async (response) => {
                        
                        //throw errors if issues
                        if (response.status === 500) {
                            // console.log("500");
                        }
                        else if(response.status === 404) {
                            // console.log("404");
                        }
                        else if(response.status === 419) {
                            // console.log("419");
                        }
        
                        const data = await response.json();

                        // console.log(currentPage);
                        setResults(data);

                        setCurrentPage(data.currentPage);
                        setLastPage(data.pageCount);

                        setPosts(data.posts);
                        setCategories(data.categories);
                        setLoading(false);
                })
            }

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetchItems(signal);
        
        return () => {
            controller.abort(); 
        };
        }, [loading]);
        useEffect(() => {
            document.title =  "Blog | Jsparrow.uk" ;
          });


    function readingTime(text) {
            const wpm = 200;
            const words = wordCount(text);
            return Math.ceil(words / wpm);     
    }

    function wordCount(text) {
            return text.trim().split(/\s+/).length;   
    }

    return (
        <div className="main-container">
        <Header toggleTheme={props.toggleTheme}/>

        <main> 
            <Search display={searchDisplay} setDisplay={setDisplay}/>
            
            {searchDisplay ? null : 
                loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> : 
                <div className="container">
                    <div className="posts_container">
                    <p className="page-num">Page {currentPage}</p>
                        {posts.map((post) => {
                            return (
                                <Link key={post.id} to={"/post/" + post.slug} className="unlinkStyle" onClick={props.toggleToTop}>
                                    <article className="post_link">
                                        <img className="post_thumbnail" src={post.image} alt={post.title} loading="lazy" />

                                        <div className="post_container">
                                           <h2 className="post_title">{post.title}</h2>
                                                {/* Strip the body of tags and get the first 200 characters
                                            <p>{(post.body.replace(/(<([^>]+)>)/ig,"").substring(0,200) + "...")}</p> */}
                                            
                                        </div>
                                        <div className='post_link_details'>
                                            <div>
                                            <p><span className='post_link_details_bold'>Posted:</span> {new Date(post.createdAt).toLocaleDateString('en-GB',{ year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                                { post.category_id ? <p><span className='post_link_details_bold'> Category: </span> {categories.find(cat => post.category_id === cat.id).title} </p> : ''}
                                            </div>
                                            
                                            <div>
                                                <p><span className='post_link_details_bold'>Words:</span> {wordCount((post.body))}</p>

                                                <p><span className='post_link_details_bold'>Reading time:</span> {readingTime((post.body))} min</p>
                                            </div>
                                            
                                        </div>
                                    </article>
                                </Link>
                            )
                        })}

                        {/* if the current page isn't 1, show last page button */}
                        {currentPage !== 1 ?
                            <button className="btn" onClick={() => {changePage(currentPage - 1); props.toggleToTop()}}>Last Page</button> :
                            null
                        }

                        {/* if the current page isn't equal to the last page, show next page button */}
                        {currentPage !== lastPage ?
                            <button className="btn" onClick={() => {changePage(currentPage + 1); props.toggleToTop()}}>Next Page</button> :
                            null
                        }
                        
                        {/* <p>Current Page: {currentPage}</p>
                        // <p>Last Page: {lastPage}</p> */}
                    </div>
                
                    <Sidebar recent={results.recent_post}/>
                    </div>
                }

            <Footer toggleToTop={props.toggleToTop} />
        </main>
            
        </div>
    );
}

export default Blog;