import React, {useState, useEffect, useLayoutEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Header from './partials/Header';
import Footer from './partials/Footer';
import Search from './partials/Search';
import Sidebar from './partials/Sidebar';
import ReactHtmlParser from 'html-react-parser'; 

// import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'react-router-dom';
import apiUrlBase from './config/apiUrlBase';

export default function CoolPeople(props) {

    let history = useNavigate();
// search state
const [searchDisplay, setSearchDisplay] = useState(false);
const setDisplay = (input) => setSearchDisplay(input);

// post state
const [post, setPost] = useState({});
const [category, setCategory] = useState({});
const [loading, setLoading] = useState(false);

useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

const fetchItem = async (signal , apiUrl = `${apiUrlBase}${window.location.pathname}`) =>  {
    
    // console.log("load");
    // console.log(apiUrl, {signal});
    try {
        const req = await fetch(apiUrl, {
            method: "GET",
            signal: signal,
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }})
            .then(async (response) => {
                
                //throw errors if issues
                if (response.status === 500) {
                    // console.log("500");
                }
                else if(response.status === 404) {
                    // console.log("404");
                }
                else if(response.status === 419) {
                    // console.log("419");
                }
                else if (response.status !== 400) {
                    // console.log(response.status);
                }

                const data = await response.json();
                setPost(data);

                if (!data.category_id) {
                    setLoading(false);
                }
                const categoryReq = await fetch(apiUrlBase + "/category/" + data.category_id, {
                    method: "GET",
                    signal: signal,
                    headers : { 
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }}).then (async (response) => {
                        // console.log("well we got here I guess")
                        const data = await response.json();
                        setCategory(data.category)
                        setLoading(false);
                    })


               
        })
    }
    catch(error) {

        // console.log("OKAY I ERROR AND LOAD IS "+loading)
        // console.log("Error ", error);
    }
}

useEffect(() => {
    const controller = new AbortController();
    fetchItem(controller.signal);
  
    return () => {
      controller.abort(); 
    };
  }, []);

  useEffect(() => {
    document.title = post.title ? post.title + " | Jsparrow.uk" : "Jsparrow.uk";
  });


    return (
        <div className="main-container"> 
            <Header toggleTheme={props.toggleTheme}/>

            <main>
            {/* <Search currentId={props.match.params.id} display={searchDisplay} setDisplay={setDisplay}/> */}
            
            {searchDisplay ? null : 
                <div className="container">
                {loading ? <div className="spinner"><ClipLoader color='#356f2a' /></div> :
                        <div className="post">
                            <div className="post_container resource-container">
                                <h1>Cool People (with cool websites)</h1>                         
                                <hr />
                                <p>I have come across most of these people via their Youtube channels, but I've tried to limit this list to those that also have a personal website. Nevertheless since Youtube is usually their main source of content, I've linked the channels as well.</p>   
                                <section>

                                <img src="/img/resources_tools/lindybeige.jpeg" alt="lindybeige" loading="lazy" />
                                <h3>Lindybeige</h3>
                                <p>A rather funny fellow who talks a lot about history among other subjects on his channel, his website is amazing too. It’s chock full of content on many different topics.</p>
                                Website: <a href="http://www.lloydianaspects.co.uk/">lloydianaspects.co.uk</a>
                                <br />
                                Youtube: <a href="https://www.youtube.com/@lindybeige">lindybeige</a>
                                </section>

                                <section>
                                <br />
                                <img src="/img/resources_tools/kliksphilip.jpeg" alt="kliksphilip" loading="lazy"/>
                                <h3>Kliksphilip</h3>
                                <p>Kliksphilip is mostly known for his CS:GO content, which is a game I’ve never cared for personally. But I absolutely love and resonate with his game making journey series, where he goes into his history since childhood of making games. </p>

                                Website: <a href="http://www.2kliksphilip.co.uk/index.html">2kliksphilip.co.uk</a>
                                <br />
                                Youtube: <a href="https://www.youtube.com/@kliksphilip">kliksphilip</a>
                                </section>

                                <section>
                                <br />
                                <img src="/img/resources_tools/lukesmith.jpeg" alt="lukesmith" loading="lazy"/>
                                <h3>Luke Smith</h3>
                                <p>Luke Smith got me back into Linux after a long hiatus with his earlier i3wm and dwm tutorials, although he nowadays makes content on a wider range of topics (which is usually quite interesting stuff). He has a bunch of useful websites available aside from his personal one, like <a href='https://landchad.net'>LandChad.net</a>, which contains a lot of helpful information on setting up server software.</p>
                                Website: <a href="https://lukesmith.xyz/">lukesmith.xyz</a>
                                <br /> 
                                Youtube: <a href="https://www.youtube.com/@LukeSmithxyz">LukeSmithxyz</a>
                                </section>

                                <section>
                                <br />
                                <img src="/img/resources_tools/distrotube.jpeg" alt="distrotube" loading="lazy"/>
                                <h3>DistroTube</h3>
                                <p>Distrotube is a linux youtuber, specialising in minimalist software like window managers, EMACs etc.</p>
                                Website: <a href="https://distro.tube/">distro.tube</a>
                                <br />
                                Youtube: <a href="https://www.youtube.com/@DistroTube">DistroTube</a>
                                </section>

                                <section>
                                <br />
                                <img src="/img/resources_tools/quidsup.jpeg" alt="quidsup" loading="lazy"/>
                                <h3>Quidsup</h3>
                                <p>Quidsup is one of the first linux channels I started watching. His website contains some useful guides for setting up a NAS server.</p>
                                Website: <a href="https://quidsup.net">quidsup.net</a>
                                <br />
                                Youtube: <a href="https://www.youtube.com/@quidsup">quidsup</a>
                                </section>



                                <button className="btn readMore" onClick={() => {history(-1); props.toggleToTop()}}>
                                    <i className="fas fa-arrow-left"></i> Go Back
                                </button>
                            </div>
                        </div>
                    } 
                    {loading ? null : <Sidebar/>}
                </div>
                 }
                <Footer toggleToTop={props.toggleToTop} />
            </main>
        </div>
    )
}
