import {useState, useEffect} from 'react'
import apiUrlBase from './config/apiUrlBase';

export default function Rss() {

  
    const [rss, setRss] = useState(true);

    const fetchItem = async (signal , apiUrl = `${apiUrlBase}/post/rss`) =>  {
        
        try {
            const req = await fetch(apiUrl, {
                method: "GET",
                signal: signal,
                headers : { 
                    'Content-Type': 'application/xml',
                    'Accept': 'application/xml'
                }})
                .then(async (response) => {
                    
                    //throw errors if issues
                    if (response.status === 500) {
                        // console.log("500");
                    }
                    else if(response.status === 404) {
                        // console.log("404");
                    }
                    else if(response.status === 419) {
                        // console.log("419");
                    }
                    else if (response.status !== 400) {
                        // console.log(response.status);
                    }
    
                    const data = await response.text();
                    setRss(data)
                    // console.log("rss value" + data)
                   
            })
        }
        catch(error) {
            // console.log("Error ", error);
        }
    }

    useEffect(() => {
        document.title = "jsparrow rss feed";
      });

    useEffect(() => {
        const controller = new AbortController();
        fetchItem(controller.signal);
      
        return () => {
          controller.abort(); 
        };
      }, []);

    return (
    <pre className="xml-body">
        {rss}
    </pre>
    )
}
